







import ProgressCentered from '../components/ProgressCentered.vue'
import { Vue, Component } from 'vue-property-decorator'

import { CONFIRM_USER } from '@/store/auth'

@Component({
  head: {
    title() {
      return {
        inner: 'Confirming email...',
      }
    },
  },
  components: {
    ProgressCentered,
  },
})
export default class ConfirmEmail extends Vue {
  errors: string[] = []

  async beforeMount() {
    this.$store
      .dispatch(CONFIRM_USER, {
        username: this.$route.query.u,
        code: this.$route.query.c,
      })
      .then(() => {
        // unfortunately cognito doesn't give us a token on confirm!
        // https://github.com/aws-amplify/amplify-js/issues/6320
        // so have get user to sign in
        this.$router.replace({
          path: '/sign-in',
          query: {
            confirmed: '1',
          },
        })
      })
      .catch(() => {
        this.errors = ['There was an error confirming your account.']
      })
  }
}
